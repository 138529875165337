.custom_navbar
  .navbar
  .navbar-content
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu {
  background-color: #fcfcfc !important;
}
.custom_navbar
  .dropdown-item:not(.dropdown-item:active, .dropdown-item.active):hover {
  background-color: rgba(60, 100, 50, 0.1) !important;
  color: #33c721 !important;
}
.custom_navbar
  .dropdown-item:not(.dropdown-item:active, .dropdown-item.active):hover
  svg {
  color: #33c721 !important;
}

.custom_navbar .navbar {
  width: 100%;
  height: 60px;
  background: #fff;
  border-bottom: 1px solid #e9ecef;
  display: flex;
  align-items: center;
  padding: 0;
  position: fixed;
  right: 0;
  left: 0px;
  top: 0;
  z-index: 978;
  box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
  transition: width 0.1s ease, left 0.1s ease;
}
.custom_navbar .dropdown-item:active {
  text-decoration: none;
  background-color: rgba(60, 100, 50, 0.1) !important;
  color: #33c721 !important;
}
.custom_navbar .dropdown-item:active svg {
  color: #0b0c0b !important;
}

.invoice-label {
  margin-bottom: 5px;
  font-weight: 500;
}
